import axios from "axios";

// Additional export for the API PDF URL
//const urlPdf = "https://dev.api.marion-materiaux.com"; // Dev
 const urlPdf = "https://recette.api.marion-materiaux.com"; // Recette
// const urlPdf = "https://api.marion-materiaux.com"; // Prod

const instance = axios.create({
//baseURL: "https://dev.api.marion-materiaux.com/api", // Dev
 baseURL: "https://recette.api.marion-materiaux.com/api", // Recette
// baseURL: "https://api.marion-materiaux.com/api", // Prod
});

//key google maps
const REACT_APP_GOOGLE_MAPS_API_KEY="AIzaSyCoMoDDmlBQA3i8TOLYp4_NBVBrSr8Dj-c"

const authToken = localStorage.getItem("authToken");
const parsedUserData = JSON.parse(localStorage.getItem("userData"));

// function checkTokenAndRedirect(authToken) {
//   // Get the current pathname
//   const currentPath = window.location.pathname;
//   //urls permis pour le founisseur
//   const allowedPaths = [
//     "/login",
//     "/reinitialiser_mot_de_passe",
//     "/changer_mot_de_passe",
//     "/fournisseur/renitialise-mot-de-passe",
//     "/fournisseur/commande",
//   ];
//   //url interdit pour l'admin
//   const restructedPaths = [
//     "/fournisseur/renitialise-mot-de-passe",
//     "/fournisseur/commande",
//     "/fournisseur/confirmation",
//   ];
//   //vérification si url commence par l'un des url enregister (cette fonction somme et ajouter pour renitialise-mot-de-passe et l'ajout d'un token qui se change à chaque fois)
//   const isAllowedPath = allowedPaths.some((allowedPath) =>
//     currentPath.startsWith(allowedPath)
//   );

//   const connected = isTokenValid();
//   //restruction des url pour le fournisseur
//   console.log(!isAllowedPath +  '&&' + parsedUserData.role  + '==' + 6)
//   if (!isAllowedPath && parsedUserData.role == 6) {
//     window.location.href = "/fournisseur/commande";
//   }else{
//     window.location.href = "/";
//   }
//   //restruction des url pour l'admin
//   if (connected && restructedPaths.includes(currentPath) && parsedUserData.role == 5) {
//     window.location.replace("/dashboard");
//     return;
//   }
// }
export const isTokenValid = (token) => {
  // Implement token validation logic here
  try {
    const parsedToken = JSON.parse(atob(token.split(".")[1])); // Decode JWT payload
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return parsedToken.exp > currentTime; // Check if token expiration is in the future
  } catch (e) {
    return false; // If there's an error, the token is invalid
  }
};

// checkTokenAndRedirect(authToken);

instance.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { urlPdf , REACT_APP_GOOGLE_MAPS_API_KEY};

export default instance;
